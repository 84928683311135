import React from "react"
import Layout from "../components/modules/layout"
// import TextBlock from "../components/blocks/text-block"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const ImpressumPage = () => {
  return (
    <Layout>
      <div className="">
        <div className="relative p-8 mx-auto prose">
          <a href="/">
            <FontAwesomeIcon
              icon={faTimes}
              size="2x"
              className="absolute right-0 mr-8"
            />
          </a>
          <h1>Impressum</h1>
          <p>Informationen nach § 5 Telemediengesetz (TMG)</p>
          <p>
            <b>Wilhelm Reuschling GmbH</b>
            <br /> Gießener Str. 18-30 <br /> 61118 Bad Vilbel
          </p>
          <p>
            Telefon: 06101/985713-0
            <br /> Telefax: 06101/403-45572
          </p>
          <p>
            E-Mail:
            <a href="mailto:info@wilhelm-reuschling.de">
              info@wilhelm-reuschling.de
            </a>
            <br /> Internet:
            <a href="http://www.wilhelm-reuschling.de/">
              www.wilhelm-reuschling.de
            </a>
          </p>
          <p>
            Registergericht: Amtsgericht Frankfurt/Main, HRB 79016
            <br /> Umsatzsteueridentifikationsnummer: DE252446350
          </p>
          <p>Geschäftsführer: Dirk Hinkel</p>
          <p>
            Rechtshinweis
            <br /> © Copyright 2020
            <br /> Alle Rechte vorbehalten.
          </p>
          <p>
            Die Inhalte und Struktur dieser Webseite sind urheberrechtlich
            geschützt. Die Rechte an dem Inhalt dieser Website liegen bei der
            Wilhelm Reuschling GmbH oder demjenigen, der der Wilhelm Reuschling
            GmbH die ausschließlichen Nutzungsrechte eingeräumt hat. Die Wilhelm
            Reuschling GmbH behält sich das Recht vor, Änderungen oder
            Ergänzungen der bereitgestellten Informationen vorzunehmen. Die
            Vervielfältigung, Verbreitung oder sonstige Nutzung von Inhalten
            oder Daten, insbesondere die Verwendung von Texten, Textteilen oder
            Bildmaterial sowie Nachdruck, Aufnahme in Online-Dienste, Internet
            und Vervielfältigung auf Datenträger wie CD-ROM, DVD-ROM, etc., auch
            auszugsweise, bedarf der vorherigen schriftlichen Zustimmung der
            Wilhelm Reuschling GmbH.
          </p>
          <p>
            Die Wilhelm Reuschling GmbH haftet nicht für unverlangt eingesandte
            Inhalte, Manuskripte und Fotos. Für Inhalte externer Links und
            fremde Inhalte übernimmt die Wilhelm Reuschling GmbH keine
            Verantwortung.
          </p>
          <p>
            <strong>Online Strategie, Webdesign und Webprogrammierung</strong>
            <br /> YUM GmbH |
            <a href="http://www.yum.de/">Webdesign Frankfurt am Main</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ImpressumPage
